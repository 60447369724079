<h1>Forgot Password</h1>
<p>Tell us your email and we’ll send you the instructions to reset your password</p>

<form class="mat-large">

    <mat-form-field class="mat-block" flex>
        <input matInput placeholder="your email address" [formControl]="email" required/>
    </mat-form-field>

    <div layout="row" layout-align="start center" class="buttons gap-15">
        <button mat-raised-button color="primary" [disabled]="!email.valid || saving" (click)="save()">
            <span *ngIf="!saving">Recover Password</span>
            <span *ngIf="saving">Please wait...</span>
        </button>
        <button mat-raised-button (click)="cancel()" [disabled]="saving">Cancel</button>
    </div>
</form>
