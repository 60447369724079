import {Component, EventEmitter, HostBinding, HostListener, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import {Notification} from '../../models';

@Component({
    selector: 'learnt-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

    click: EventEmitter<any> = new EventEmitter();

    n: Notification;

    @HostBinding('class.actionable')
    actionable: boolean;

    @Output()
    dismiss: EventEmitter<any> = new EventEmitter(true);

    constructor(private router:Router) {
     
        
        setTimeout(() => {
            this.dismiss.next();
        }, 30000);
    }

    ngOnInit(): void {
    }

    close(event: MouseEvent) {
        event.stopPropagation();
        this.dismiss.next();
    }

    public goto():void{
        if(this.n.action)
            this.router.navigate([this.n.action])
    }

    set notification(n: Notification) {        
        this.n = n;
        this.actionable = n.action !== '';
    }

    @HostListener('click', ['$event'])
    onNotificationClick() {
        this.click.next();
        this.dismiss.next();
    }
}
