<!-- TABS -->
<nav (click)="onTabClick($event)" class="filters-menu" [class.mobile-menu-expanded]="mobile && menuExpanded">
    <!-- Tabs -->
    <ng-container>
        <ng-container *ngTemplateOutlet="filtersTab"></ng-container>
    </ng-container>
</nav>
<!-- TABS -->

<!-- TAB CONTENTS -->
<div [ngSwitch]="state" class="contents">
    <!-- When filter -->
    <ng-container *ngSwitchCase="'when'">
        <ng-container *ngTemplateOutlet="whenTabContent"></ng-container>
    </ng-container>

    <!-- Where filter -->
    <ng-container *ngSwitchCase="'where'">
        <ng-container *ngTemplateOutlet="whereTabContent"></ng-container>
    </ng-container>

    <!-- Price filter -->
    <ng-container *ngSwitchCase="'price'">
        <ng-container *ngTemplateOutlet="priceTabContent"></ng-container>
    </ng-container>
</div>
<!-- TAB CONTENTS -->

<!-- TEMPLATES -->
<!-- Filters tab template -->
<ng-template #filtersTab>
    <!-- When filter -->
    <a data-state="when" [class.active]="state === 'when'">
        <div class="menu-filter">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.3125 2.8125H1.6875C1.0665 2.8125 0.5625 3.3165 0.5625 3.9375V16.3125C0.5625 16.9335 1.0665 17.4375 1.6875 17.4375H16.3125C16.9335 17.4375 17.4375 16.9335 17.4375 16.3125V3.9375C17.4375 3.3165 16.9335 2.8125 16.3125 2.8125Z" stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M0.5625 6.1875H17.4375" stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.0625 1.5625V3.8125" stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.9375 1.5625V3.8125" stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.1875 8.4375V15.1875" stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.8125 8.4375V15.1875" stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.8125 11.8125H15.1875" stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            <span>When<em *ngIf="hasFilterFor('when')"></em></span>
        </div>
    </a>
    <!-- <ng-container *ngIf="mobile && state === 'when'">
        <ng-container *ngTemplateOutlet="whenTabContent"></ng-container>
    </ng-container> -->

    <!-- Where filter -->
    <a data-state="where" [class.active]="state === 'where'">
        <div class="menu-filter">
            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.83252 14.5V10" stroke="#8F7677" stroke-width="1.6" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M7.83222 10C10.3478 10 12.3871 7.98528 12.3871 5.5C12.3871 3.01472 10.3478 1 7.83222 1C5.31663 1 3.27734 3.01472 3.27734 5.5C3.27734 7.98528 5.31663 10 7.83222 10Z"
                    stroke="#8F7677" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M10.6791 13.5786C13.0317 13.9341 14.6646 14.7171 14.6646 15.625C14.6646 16.8681 11.606 17.875 7.83232 17.875C4.0586 17.875 1 16.8681 1 15.625C1 14.7171 2.63292 13.9375 4.98552 13.5786"
                    stroke="#8F7677" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <span>Where<em *ngIf="hasFilterFor('where')"></em></span>
        </div>
    </a>
    <!-- <ng-container *ngIf="mobile && state === 'where'">
        <ng-container *ngTemplateOutlet="whereTabContent"></ng-container>
    </ng-container> -->

    <!-- Price selector -->
    <a data-state="price" [class.active]="state === 'price'">
        <div class="menu-filter">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.18064 1.31936C3.65348 0.846518 4.33136 0.641272 4.98708 0.772416L8.96871 1.56874C9.35589 1.64618 9.71149 1.83649 9.99069 2.11569L16.0233 8.14829C16.8043 8.92934 16.8043 10.1957 16.0233 10.9767L10.9767 16.0233C10.1957 16.8043 8.92933 16.8043 8.14829 16.0233L2.11569 9.99069C1.83649 9.71149 1.64618 9.35589 1.56874 8.96871L0.772416 4.98708C0.641272 4.33136 0.846517 3.65348 1.31936 3.18064L3.18064 1.31936Z"
                    stroke="#8F7677" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M5.625 6.75C6.24632 6.75 6.75 6.24632 6.75 5.625C6.75 5.00368 6.24632 4.5 5.625 4.5C5.00368 4.5 4.5 5.00368 4.5 5.625C4.5 6.24632 5.00368 6.75 5.625 6.75Z"
                    fill="#8F7677" stroke="#8F7677" stroke-width="1.6" />
            </svg>

            <span>Price<em *ngIf="hasFilterFor('price')"></em></span>
        </div>
    </a>
    <!-- <ng-container *ngIf="mobile && state === 'price'">
        <ng-container *ngTemplateOutlet="priceTabContent"></ng-container>
    </ng-container> -->

    <!-- Instant session -->
    <ng-template #instantSessionTooltip>
        <div class="tooltip-wrapper">
            <div class="title">Instant Session</div>
            <div class="content">Find tutors who are available to meet online right now</div>
        </div>
    </ng-template>
    <a class="menu-checkbox no-underline a-instant-session" data-state="instant-session" (click)="instantSession.toggle($event)"
        [learntPopoverTooltip]="instantSessionTooltip" direction="bottom" [offsetY]="-15">
        <div class="menu-filter instant-session">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6875 0.5625L8.4375 7.3125H17.4375L7.3125 17.4375L8.4375 10.6875H0.5625L10.6875 0.5625Z"
                    stroke="#78C7D6" stroke-width="1.6" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>

            <span>Instant Session</span>
            <learnt-checkbox #instantSession type="checkbox" [checked]="filters.instantSession"
                (change)="onInstantSessionChange($event)"></learnt-checkbox>
        </div>
    </a>

    <!-- Test Prep -->
    <a class="menu-checkbox no-underline" data-state="test-prep" (click)="testPrep.toggle($event)">
        <div class="menu-filter test-prep">
            <span>Test Prep</span>
            <learnt-checkbox #testPrep type="checkbox" [checked]="filters.testPrep"
                (change)="onTestPrepChange($event)"></learnt-checkbox>
        </div>
    </a>

    <!-- Clear filters -->
    <div class="location-clear-filter">
        <div class="location-icon" (click)="toggleMapFlag()">
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.15622 9.41075L6 16L10.8438 9.41075C13.7229 5.49416 10.895 0 6 0C1.10501 0 -1.72288 5.49416 1.15622 9.41075Z"
                    fill="#FF676B" />
                <circle cx="6" cy="6" r="2" fill="white" />
            </svg>
        </div>
        <a class="icon-button clear-filters-button" (click)="clear()">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 14H0V16H16V14Z" fill="#78C7D6" />
                <path
                    d="M4.6 12H9.4L14.7 6.7C15.1 6.3 15.1 5.7 14.7 5.3L9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L1.3 7.3C0.9 7.7 0.9 8.3 1.3 8.7L4.6 12Z"
                    fill="#78C7D6" />
            </svg>
            <span *ngIf="!mobile">Clear Filters</span>
        </a>
    </div>

    <div class="float-clear"></div>
</ng-template>

<!-- When tab content template -->
<ng-template #whenTabContent>
    <div class="when">
        <div [ngSwitch]="availabilityType">
            <div class="gap-10" *ngSwitchCase="'general'">
                <learnt-general-availability [availability]="filters.when"
                    (change)="onGeneralAvailabilityChange($event)"></learnt-general-availability>
            </div>
            <div class="gap-10" *ngSwitchCase="'specific'">
                <learnt-specific-availability [value]="filters.specific" [intervalRange]="mobile ? [11, 17] : [8, 20]"
                    (change)="onSpecificAvailabilityChange($event)"></learnt-specific-availability>
            </div>
            <div class="availability-toggle-wrapper">
                <div class="availability-toggle" (click)="toggleAvailability()">
                    <div [ngSwitch]="availabilityType" class="availability-text">
                        <span *ngSwitchCase="'general'">Specific Times</span>
                        <span *ngSwitchCase="'specific'">Generic Times</span>
                    </div>
                </div>
            </div>

            <div class="float-clear"></div>
        </div>
    </div>
</ng-template>

<!-- Where tab content template -->
<ng-template #whereTabContent>
    <div class="where">
        <div class="checkboxes gap-20" layout="row" layout-align="center center">
            <learnt-checkbox name="whereOnline" [(ngModel)]="whereOnline" (change)="onWhereOnlineChange($event)">
                Online
            </learnt-checkbox>
            <learnt-checkbox name="whereInPerson" [(ngModel)]="whereInPerson" (change)="onWhereInPersonChange($event)">
                In Person
            </learnt-checkbox>
            <learnt-checkbox name="whereBoth" [(ngModel)]="whereBoth" (change)="onBothWhereChange($event)">Both
            </learnt-checkbox>
        </div>
        <div class="location center" *ngIf="whereInPerson">
            <input id="search-filters-address" type="text" [(ngModel)]="whereInPersonZipCode" placeholder="Your address"
                #searchInput />
            <div class="where-search">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path
                            d="M18 9C18 4.05 13.95 -1.77031e-07 9 -3.93402e-07C4.05 -6.09774e-07 -1.77031e-07 4.05 -3.93402e-07 9C-6.09774e-07 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9ZM7.875 10.125L3.375 7.875L12.375 5.625L10.125 14.625L7.875 10.125Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="18" height="18" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <!-- <div *ngIf="isLocationAvailable()" class="center" layout-align="center center" layout="row">
                <a *ngIf="!locating" (click)="getMyLocation($event)" class="icon-button">
                    <svg width="11px" height="15px" viewBox="0 0 11 15" version="1.1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Desktop---015-Search-Where-InPerson" transform="translate(-721.000000, -312.000000)"
                                fill="#F37073">
                                <g id="Filters" transform="translate(0.000000, 140.000000)">
                                    <g id="btn-current" transform="translate(721.000000, 171.000000)">
                                        <path
                                            d="M5.09345657,7.905223 C5.59482882,7.905223 6.02212675,7.72860682 6.37536569,7.37536569 C6.72860682,7.02212675 6.905223,6.59482882 6.905223,6.09345657 C6.905223,5.59208433 6.72860682,5.16478639 6.37536569,4.81154746 C6.02212675,4.45830633 5.59482882,4.28169014 5.09345657,4.28169014 C4.59208433,4.28169014 4.16478639,4.45830633 3.81154746,4.81154746 C3.45830633,5.16478639 3.28169014,5.59208433 3.28169014,6.09345657 C3.28169014,6.59482882 3.45830633,7.02212675 3.81154746,7.37536569 C4.16478639,7.72860682 4.59208433,7.905223 5.09345657,7.905223 Z M5.09345657,1 C6.50641452,1 7.70854793,1.49566648 8.69989621,2.48701694 C9.69124667,3.47836521 10.1869131,4.68049863 10.1869131,6.09345657 C10.1869131,6.79993445 10.010297,7.60895421 9.65705583,8.52053991 C9.30381689,9.43212561 8.87651896,10.2867237 8.37514671,11.0843603 C7.87377447,11.881997 7.37810799,12.6283474 6.88813196,13.3234291 C6.39815375,14.0185138 5.98224984,14.5711541 5.64040493,14.9813674 L5.09345657,15.5625 C4.95671948,15.4029726 4.77440409,15.1921718 4.54650822,14.930091 C4.31861234,14.6680102 3.90840545,14.1438566 3.31587441,13.3576155 C2.72334338,12.5713729 2.20488885,11.8079314 1.76049002,11.0672671 C1.3160923,10.3266049 0.911582418,9.48909793 0.546948357,8.55472418 C0.182314296,7.62035042 0,6.79993445 0,6.09345657 C0,4.68049863 0.495666916,3.47836521 1.48701585,2.48701694 C2.47836521,1.49566648 3.68049863,1 5.09345657,1 Z"
                                            id="icon-location"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <span>Use my current location</span>
                </a>
                <div class="findingme" *ngIf="locating">
                    <learnt-spinner></learnt-spinner>
                </div>
            </div> -->
        </div>
    </div>
</ng-template>

<!-- Price tab content template -->
<ng-template #priceTabContent>
    <div class="price">
        <learnt-price-interval (change)="onPriceChange($event)"
            [default]="filters.price ? filters.price : defaultPriceInterval" [min]="10" [max]="300" [step]="5">
        </learnt-price-interval>
    </div>
</ng-template>
<!-- TEMPLATES -->