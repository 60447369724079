<div class="menu__container header-component">
    <header [class.search]="state == 'search'" [class.account]="state == 'account'" *ngIf="!headless">


        <!-- LEFT  logo and search icon -->
        <div class="left" flex>
            <div class="logo">
                <a href="{{ environment.REFERRAL_URL}}" alt="Go Home">

                    <img src="/assets/logo.svg" />
                </a>
                <div class="search-icon" [@search]="state" (click)="showSearch()" *ngIf="searchIsVisible()">
                    <div class="icon" [class.search-status]="state==='search'" layout="row"
                        layout-align="center center">
                        <img src="/assets/search.svg" alt="Search Icon" />
                    </div>
                </div>
            </div>
            <div flex class="gap"></div>
        </div>
        <!-- END LEFT -->

        <!-- CENTER -->
        <div class="center" flex>
            
            <a *ngFor="let item of menuItems" #rla="routerLinkActive" routerLinkActive="" 
                [class]="item.class"
               
                [routerLink]="item.link" 
                [ngClass]="isActive(rla, item) ? 'active' : 'inactive'">
                <span>{{item.title}}</span>
            </a>
           
        </div>

        <!-- RIGHT -->
        <div class="right right-states" flex>
            <div class="nav-mobile"><a id="navbar-toggle" href="#!"><span></span></a></div>
            
            
            <div class="state account" [class.visible]="true" layout="row" layout-align="end center">

                <!-- MENU MOBILE -->
                <div layout="row" layout-align="end center" class="hide-gt-sm mobile" *ngIf="mobile">
                    <!-- Interactions -->
                    <div *ngIf="me" layout="row" layout-align="end center">

                        <learnt-header-notifications></learnt-header-notifications>
                        <learnt-header-inbox></learnt-header-inbox>
                        <div *ngIf="me" class="user-mobile" routerLink="/main/dashboard" >
                            <img src="/assets/icons/user.svg " alt="User" />
                        </div>
                    </div>
                    <!-- Interactions -->

                    <div class="toggle-menu">
                        <mat-icon svgIcon="menu" (click)="showMenu()"></mat-icon>
                    </div>
                   
                </div>
                <!-- MENU DESKTOP -->
                <div layout="row" layout-align="end center" class="hide-xs hide-sm" *ngIf="!mobile">

                    <nav class="head-links" layout="row" *ngIf="showLinks()">
                      
                        <ng-container *ngIf="!me">

                            <a class="link-button" [routerLink]="['/start/apply']">
                                Become a Tutor
                            </a>
                            
                            
                            
                            <button class="login-button" learnt-button="red" [routerLink]="['/start/login']">
                                Log in/Sign up
                            </button>
                            
                        </ng-container>
                        
                    </nav>


                    <!-- Interactions -->
                    <div *ngIf="me" class="buttons-with-icons" layout="row" layout-align="end center">
                        <learnt-header-notifications></learnt-header-notifications>
                        <learnt-header-inbox></learnt-header-inbox>

                        <div [matTooltip]="me.shortName"  *ngIf="me" class="user" (click)="logout($event)" >
                            <img src="/assets/icons/user.svg " alt="User" />
                        </div>

                        <div class="items">
                            <button class="admin-button" (click)="admin($event)" *ngIf="me.isAdmin()" >
                                Admin
                            </button>
<!--                            <a (click)="admin($event)" *ngIf="me.isAdmin()">Admin</a>
                                <a (click)="logout($event)">Logout</a>-->
                        </div>

                    </div>
                    <!-- Interactions -->

                    


                  

                    <!-- Current User 
                    <div *ngIf="me" class="user" routerLink="/main/dashboard" layout="row">
                        <div class="left">
                          <div class="name">{{me.shortName}}</div>
                            <div layout="row" layout-align="end center">
                                <a (click)="admin($event)" *ngIf="me.isAdmin()">Admin</a>
                                <a (click)="logout($event)">Logout</a>
                            </div>
                        </div>
                        <img [src]="me.avatar" class="avatar" />
                    </div>
                     Current User -->
                </div>
            </div>
            <!-- STATE ACCOUNT -->

        </div>
    </header>

    <div class="search-box" [class.visible]="state==='search'">
        <div class="search-input-wrapper">
            <img *ngIf="!searchWorking" src="/assets/search.svg" alt="search icon" />
            <learnt-spinner class="search-spinner" *ngIf="searchWorking"></learnt-spinner>
            <input class="search-input" (keyup)="onSearchInputKeyUp($event)" (focus)="hintIndex = -1"
                (blur)="onInputBlur($event)" type="text" #searchInput
                [attr.placeholder]="mobile ? 'Search' : 'What do you want to learn today?'" />
            <div class="autocomplete" *ngIf="state === 'search' && subjects && subjects.length"
                [scrollTop]="hintIndex*20">
                <div class="hint" [class.current]="i == hintIndex" (click)="hintSelect(subject)"
                    *ngFor="let subject of subjects; let i = index">
                    <span>{{subject.name}}</span>
                </div>
            </div>
        </div>

        <div class="state search" layout="row" layout-align="end center">
            <a class="icon-button" [class.set]="filtersVisibility" [class.hide-filter]="filtersVisibility"
                (click)="toggleFilters()">
                <span *ngIf="!filtersVisibility" class="hide-xs hide-sm">Show Filters</span>
                <span *ngIf="filtersVisibility" class="hide-xs hide-sm">Hide Filters</span>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path
                            d="M3 8C3 8.55229 3.44772 9 4 9H5C5.55228 9 6 8.55229 6 8V1C6 0.447715 5.55228 0 5 0H4C3.44772 0 3 0.447715 3 1V2C3 2.55228 2.55228 3 2 3H1C0.447715 3 0 3.44772 0 4V5C0 5.55228 0.447715 6 1 6H2C2.55228 6 3 6.44772 3 7V8Z"
                            fill="#78C7D6" stroke="#FCF5F5" stroke-width="0.5" />
                        <path
                            d="M17 3H8.5C7.94772 3 7.5 3.44772 7.5 4V5C7.5 5.55228 7.94772 6 8.5 6H17C17.5523 6 18 5.55228 18 5V4C18 3.44772 17.5523 3 17 3Z"
                            fill="#78C7D6" stroke="#FCF5F5" stroke-width="0.5" />
                        <path
                            d="M15 10C15 9.44772 14.5523 9 14 9H13C12.4477 9 12 9.44772 12 10V17C12 17.5523 12.4477 18 13 18H14C14.5523 18 15 17.5523 15 17V16C15 15.4477 15.4477 15 16 15H17C17.5523 15 18 14.5523 18 14V13C18 12.4477 17.5523 12 17 12H16C15.4477 12 15 11.5523 15 11V10Z"
                            fill="#78C7D6" stroke="#FCF5F5" stroke-width="0.5" />
                        <path
                            d="M9.5 12H1C0.447715 12 0 12.4477 0 13V14C0 14.5523 0.447715 15 1 15H9.5C10.0523 15 10.5 14.5523 10.5 14V13C10.5 12.4477 10.0523 12 9.5 12Z"
                            fill="#78C7D6" stroke="#FCF5F5" stroke-width="0.5" />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="18" height="18" rx="1" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </a>
            <a class="icon-button" (click)="closeSearch()">
                <!-- <span>
                    <ng-container *ngIf="me; else loggedOut">Back to Dashboard</ng-container>
                    <ng-template #loggedOut>Back to Homepage</ng-template>
                </span> -->
                <svg class="close-icon" width="10px" height="10px" viewBox="0 0 10 10" version="1.1">
                    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Buttons-/-Close" transform="translate(-46.000000, -6.000000)" stroke="#F37073"
                            stroke-width="0.2" fill="#F37073">
                            <path
                                d="M51.8338441,10.9995852 L54.8274615,8.00670522 C55.0575128,7.7759165 55.0575128,7.40282034 54.8274615,7.17203161 C54.5966727,6.94271757 54.2235766,6.94271757 53.9935252,7.17203161 L50.9999078,10.165649 L48.0070278,7.17203161 C47.7762391,6.94271757 47.4031429,6.94271757 47.1730915,7.17203161 C46.9423028,7.40282034 46.9423028,7.7759165 47.1730915,8.00670522 L50.1659716,10.9995852 L47.1730915,13.9924653 C46.9423028,14.223254 46.9423028,14.5963501 47.1730915,14.8271389 C47.2881172,14.9421646 47.4385355,14.9996774 47.589691,14.9996774 C47.7408466,14.9996774 47.8912648,14.9421646 48.0070278,14.8271389 L50.9999078,11.8335215 L53.9935252,14.8271389 C54.1085509,14.9421646 54.2589691,14.9996774 54.4101247,14.9996774 C54.5612802,14.9996774 54.7116984,14.9421646 54.8274615,14.8271389 C55.0575128,14.5963501 55.0575128,14.223254 54.8274615,13.9924653 L51.8338441,10.9995852 Z"
                                id="Fill-1"></path>
                        </g>
                    </g>
                </svg>
            </a>
        </div>


    </div>
</div>



