import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { TOKEN_STORAGE, Auth } from "./auth";
import { TokenStorage } from './storage';
import { ROUTE_LOGIN } from '../../../../routes';
import { SocketService } from '../../../lib/core/socket';
import { Router } from '@angular/router'
import { User, UserLocation } from '../../../models';


@Injectable({providedIn: 'root'})
export class HttpAuthorizationInterceptor implements HttpInterceptor {

    constructor(@Inject(TOKEN_STORAGE) private tokenProvider: TokenStorage,
                private auth: Auth,
                private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


       


        return new Observable(sub => {
            this.tokenProvider.get().then(token => {
        
                let noredirect: boolean;


                if (request.url.indexOf('@api') === 0) {
                    // if it is calling to api                            

                    let headers = {}

                    if (token) {
                        headers = {
                            'Authorization': `Bearer ${token}`,
                        }
                    }

                    if (request['skipRedirect']) {
                        noredirect = true;
                    }

                    request = request.clone({
                        url: environment.API_HOST + request.url.substring(4),
                        setHeaders: headers,
                    });

                
                }


                next.handle(request).subscribe(
                    
                    (response: HttpResponse<any>) => {
                        if (response instanceof HttpResponse && response.type == HttpEventType.Response) {
                            sub.next(response)
                            sub.complete()
                        }
                    },

                    (response: HttpErrorResponse) => {
                        if (response.status === 401) {
                            
                            if(this.auth.isLoggedIn()){
                                this.auth.logout()
                                this.router.navigateByUrl(ROUTE_LOGIN)
                            }
                        }
                        /*if (response.status === 403) {
                            if (typeof response.error === "string" && response.error.includes("https://")) {
                                window.location.href = response.error
                            }
                        }*/
                        sub.error(response)
                        sub.complete()
                    },

                    () => {
                        sub.complete()
                    }
                );

            }).catch((err) => {
                sub.error(err)
                sub.complete()
            })
        });
    }
}
