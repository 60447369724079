<learnt-box [padding]="padding" headline="Notifications">
    <head-left>
        <div class="counter" *ngIf="counter && count > 0">{{count}}</div>
    </head-left>

    <head-right>
        <a *ngIf="!deletingAll" (click)="clearAll()" class="clear-all">Clear All</a>
        <learnt-spinner *ngIf="deletingAll"></learnt-spinner>
    </head-right>

    <div class="no-results" *ngIf="!loading && notifications && count === 0">
        <div layout="column" layout-align="center center">
            <mat-icon svgIcon="alarm"></mat-icon>
            <span>No notifications</span>
        </div>
    </div>

    <learnt-spinner class="center-loading" *ngIf="loading"></learnt-spinner>

    <ul *ngIf="notifications && count" [class.paginated]="paginated">
        
        
        
        <li *ngFor="let notification of notifications" layout="row" class="notification-row">
            <div class="icon" layout="row" layout-align="center center">
                <img width="20" src="/assets/icons/info-green.svg"/>
            </div>

            <ng-container [ngSwitch]="notification.type">
                <div flex class="notification-content" *ngSwitchCase="1" routerLink="/main/account/calendar/details/{{notification.data.lesson._id}}">                   
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>
                
                <div flex class="notification-content" *ngSwitchCase="2" routerLink="/main/account/calendar/details/{{notification.data.lesson._id}}">
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>

                <div flex class="notification-content" *ngSwitchCase="7" routerLink="/main/account/calendar/details/{{notification.data.lesson._id}}">    
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>                    

                <div flex class="notification-content" *ngSwitchCase="14" routerLink="/main/account/calendar/details/{{notification.data.lesson._id}}">
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>

                <div flex class="notification-content" *ngSwitchCase="15" routerLink="/main/account/calendar/details/{{notification.data.lesson._id}}">
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>

                <div flex class="notification-content" *ngSwitchCase="16" (click)="rateTutor(notification)">
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>

                <div flex class="notification-content" *ngSwitchCase="18" routerLink="/main/tutor/{{notification.data.tutor}}">
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>

                <div flex class="notification-content" *ngSwitchDefault>
                    <div class="title">{{notification.title}}</div>
                    <div class="text">{{notification.message}}</div>
                </div>                    

            </ng-container>
            <div>
                <button *ngIf="!notification.deleting" matTooltip="Delete this notification" class="close" (click)="remove(notification, $event)">
                    <mat-icon svgIcon="close" color="learnt-red"></mat-icon>
                </button>
            </div>

            <learnt-spinner *ngIf="notification.deleting"></learnt-spinner>
        </li>



    </ul>
    <learnt-pagination *ngIf="paginated" [pages]="pages" (page)="getPage($event)"></learnt-pagination>
</learnt-box>
