<div class="star" *ngFor="let index of [0,1,2,3,4]">
    <svg *ngIf="show(index, 1)" xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
        <path d="M4.81958 0.770918C4.93932 0.402394 5.46068 0.402394 5.58042 0.770918L6.50218 3.6078C6.55573 3.77261 6.70931 3.88419 6.8826 3.88419H9.86548C10.253 3.88419 10.4141 4.38004 10.1006 4.6078L7.6874 6.36109C7.5472 6.46295 7.48854 6.64349 7.54209 6.8083L8.46385 9.64518C8.58359 10.0137 8.16179 10.3202 7.84831 10.0924L5.43511 8.33911C5.29492 8.23725 5.10508 8.23725 4.96489 8.33911L2.55169 10.0924C2.23821 10.3202 1.81641 10.0137 1.93615 9.64518L2.85791 6.8083C2.91146 6.64349 2.8528 6.46295 2.7126 6.36109L0.299409 4.6078C-0.0140761 4.38004 0.147034 3.88419 0.534523 3.88419H3.5174C3.69069 3.88419 3.84427 3.77261 3.89782 3.6078L4.81958 0.770918Z" fill="#78C7D6"/>
        </svg>

    <svg *ngIf="show(index, 0)" (mouseover)="onIndexHover(index)" width="11px" height="10px" viewBox="0 0 11 10"
        >
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Rating" transform="translate(0.000000, -4.000000)" [attr.fill]="getEmptyFill(index)">
                <path
                    d="M10.5426641,7.67591727 C10.5426641,7.76358253 10.487755,7.85921541 10.3779356,7.96281859 L8.07806299,10.0787128 L8.62293689,13.0672644 C8.62716044,13.0951576 8.62927222,13.1350046 8.62927222,13.1868064 C8.62927222,13.2704863 8.60709714,13.3412147 8.56274699,13.3989936 C8.51839684,13.4567725 8.45398481,13.4856616 8.36950664,13.4856616 C8.28925486,13.4856616 8.20477811,13.4617534 8.11607781,13.4139364 L5.27133275,12.0033399 L2.42658628,13.4139364 C2.33366242,13.4617534 2.24918709,13.4856616 2.17315745,13.4856616 C2.08445714,13.4856616 2.01793191,13.4567725 1.97358176,13.3989936 C1.92923161,13.3412147 1.90705653,13.2704863 1.90705653,13.1868064 C1.90705653,13.1628979 1.91128009,13.123051 1.91972861,13.0672644 L2.4646011,10.0787128 L0.158393339,7.96281859 C0.0527972735,7.85522959 0,7.75959805 0,7.67591727 C0,7.52848214 0.118265889,7.43683374 0.354801216,7.40097074 L3.53534008,6.96464204 L4.96088169,4.24506026 C5.04113488,4.08168586 5.14461763,4 5.27133275,4 C5.39804788,4 5.50153062,4.08168586 5.58178382,4.24506026 L7.00732401,6.96464204 L10.1878629,7.40097074 C10.4243989,7.43683374 10.5426641,7.52848214 10.5426641,7.67591727 Z"
                    id="Page-1"></path>
            </g>
        </g>
    </svg>

    <svg *ngIf="show(index, 0.5)" width="11px" height="10px" viewBox="0 0 11 10">
        <defs>
            <path
                d="M10.5426641,3.67591727 C10.5426641,3.76358253 10.487755,3.85921541 10.3779356,3.96281859 L8.07806299,6.0787128 L8.62293689,9.0672644 C8.62716044,9.09515763 8.62927222,9.13500458 8.62927222,9.18680643 C8.62927222,9.27048627 8.60709714,9.34121468 8.56274699,9.39899361 C8.51839684,9.45677255 8.45398481,9.48566159 8.36950664,9.48566159 C8.28925486,9.48566159 8.20477811,9.46175343 8.11607781,9.41393636 L5.27133275,8.0033399 L2.42658628,9.41393636 C2.33366242,9.46175343 2.24918709,9.48566159 2.17315745,9.48566159 C2.08445714,9.48566159 2.01793191,9.45677255 1.97358176,9.39899361 C1.92923161,9.34121468 1.90705653,9.27048627 1.90705653,9.18680643 C1.90705653,9.16289795 1.91128009,9.123051 1.91972861,9.0672644 L2.4646011,6.0787128 L0.158393339,3.96281859 C0.0527972735,3.85522959 0,3.75959805 0,3.67591727 C0,3.52848214 0.118265889,3.43683374 0.354801216,3.40097074 L3.53534008,2.96464204 L4.96088169,0.245060259 C5.04113488,0.0816858604 5.14461763,0 5.27133275,0 C5.39804788,0 5.50153062,0.0816858604 5.58178382,0.245060259 L7.00732401,2.96464204 L10.1878629,3.40097074 C10.4243989,3.43683374 10.5426641,3.52848214 10.5426641,3.67591727 Z"
                id="path-1"></path>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
            </mask>
            <use fill="#78C7D6" xlink:href="#path-1"></use>
            <rect id="Rectangle" fill="#C4C4C4" mask="url(#mask-2)" x="5.27133204" y="0" width="5.27133204" height="9.48566159"></rect>
        </g>
    </svg>
</div>
