<div class="menu">
    <!--<img src="assets/learnt-logo-tm.svg" class="site-logo mb-4">-->
    <img src="/assets/logo.svg" />

    <ng-container *ngIf='me; else visitor'>
        <a class="link my-3" routerLink="/main/dashboard">Dashboard</a> 
        <a class="link my-3" routerLink="/main/referrals">Invites</a> 
        <a class="link my-3" routerLink="/main/account">Your Account</a> 

        <a class="link my-3" (click)="admin($event)" *ngIf="me.isAdmin()">Admin</a>
        
        <a class="link link-logout my-3" (click)="logout($event)">Logout</a>
    </ng-container>
    

    <ng-template #visitor>
        <a class="link my-3" routerLink="/main/tutors">Browse Tutors</a> 
        <a class="link my-3" routerLink="/main/tutors" [queryParams]="{instantSession: true}">Jump into a Session</a>
        <a class="link my-3" routerLink="/" fragment="vc">Virtual Classroom</a>
        <a class="link my-3" routerLink="/start/apply">Become a Tutor</a>        
    </ng-template>

</div>




