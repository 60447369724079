<div class="panel" #panel>
    <div class="close">
        <p (click)="close()">
            Close
            <a type="button">
                <mat-icon svgIcon="close" color="learnt-red"></mat-icon>
            </a>
        </p>
    </div>

    <div class="content" *ngIf="!loading; else placeholder">
        <div class="text-wrapper">
            <h4 *ngIf="me">Hey {{me.profile.first_name}}!</h4>

            <h3>{{notification.title}}</h3>

            <p>This lesson is protected by our 24h cancellation policy.</p>
        </div>

        <div flex layout="row" layout-align="start center" class="buttons gap-5">
            <ng-container *ngIf="accepted && calendarLinks; else declined">
                <a *ngIf="calendarLinks.googleLink" mat-raised-button color="primary" [href]="calendarLinks.googleLink" target="_blank"> 
                    <mat-icon class="share-icon" style="margin-top: -16px;" svgIcon="google-g" color="learnt-yellow"></mat-icon>
                    <span>Add to Google Cal</span>
                </a>
                <a *ngIf="calendarLinks.icalLink" mat-raised-button color="primary" type="button" [href]="calendarLinks.icalLink" target="_blank">
                    <mat-icon  class="share-icon" style="margin-top: -16px;" svgIcon="apple" color="learnt-yellow"></mat-icon>
                    <span>Add to iCal</span>
                </a>
            </ng-container>

            <ng-template #declined>
                <button mat-raised-button color="primary" type="submit" routerLink="/tutors" (click)="close()">Book a new lesson</button>
                <button mat-raised-button type="button" (click)="close()">Cancel</button>
            </ng-template>
        </div>
    </div>

    <ng-template #placeholder>
        <learnt-spinner></learnt-spinner>
    </ng-template>
</div>
