import {Component, ElementRef, EventEmitter, HostBinding, OnInit} from '@angular/core';

@Component({
    selector: 'learnt-modal-alert',
    templateUrl: './modal-alert.component.html',
    styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {

    @HostBinding('attr.tabindex')
    tabIndex = '1';

    title: string;

    icon: string;

    message: string;

    buttons: any;

    result: EventEmitter<any> = new EventEmitter(true);

    closeButton: boolean;

    constructor(private eref: ElementRef) {
    }

    ngOnInit() {
        /*if (this.buttons && this.buttons.length) {
            return setTimeout(this.focusFirstButton.bind(this));
        }*/

        this.checkMessage(this.message)

        setTimeout(() => {
            this.eref.nativeElement.focus();
        });
    }

    checkMessage(message : any){
        if((message) && (message.statusText) && (message.error) && (message.error.message)){
            this.title = message.statusText;
            this.message = message.error.message;
        }

        if(!this.message && !this.title){
            this.title = 'An error occurred';
        }

        if(!this.message && !this.icon)
            this.icon = 'assets/logo.svg';

    }

    focusFirstButton() {
        const buttons = this.eref.nativeElement.getElementsByTagName('button');
        if (buttons.length) {
            buttons[0].focus();
            return;
        }
    }
}


/*{"headers":{"normalizedNames":{},"lazyUpdate":null},"status":401,"statusText":"Unauthorized","url":"https://api.next.learnt.io/me/transactions?from=2022-02-01T03%3A00%3A00Z&to=2022-03-01T02%3A59%3A59Z","ok":false,"name":"HttpErrorResponse","message":"Http failure response for https://api.next.learnt.io/me/transactions?from=2022-02-01T03%3A00%3A00Z&to=2022-03-01T02%3A59%3A59Z: 401 Unauthorized","error":{"message":"token is invalid, logout and login again to make a new one","code":0}}*/