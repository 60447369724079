<div class="top">
    <div class="picker-wrapper">
        <!-- <div class="arrow" (click)="navigateWeek(-1)">
            <mat-icon svgIcon="arrow-left" color="learnt-red"></mat-icon>
        </div> -->

        <div class="days-wrapper">
            <div *ngFor="let date of selectorDates" class="day-wrapper" [class.sunday]="date.weekday() === 0">
                <div class="day" [class.active]="isCurrentDay(date)" 
                    (click)="setDate(date)">
                    <div class="date">{{date.format('MMM DD')}}</div>
                </div>
                <div class="weekday">{{date.format('ddd')}}</div>
            </div>
        </div>

        <!-- <div class="arrow" (click)="navigateWeek(1)">
            <mat-icon svgIcon="arrow-right" color="learnt-red"></mat-icon>
        </div> -->
    </div>
    <div class="timezone">
        <span>Your account is set to </span>
        <a (click)="popUpTimezoneModal()">{{timezone ? timezone : 'Set timezone'}}</a>
    </div>
</div>

<div class="bottom">
    <learnt-time-interval [interval]="intervalRange" [max]="12 * 60" [fromRange]="fromRange" [toRange]="toRange"
        (change)="timeChanged($event)">
    </learnt-time-interval>
</div>

<!-- Modal for Timezone setting -->
<div *ngIf="timezoneModal" id="timezone-modal" class="timezone-set">
    <div class="modal-window">
        <a class="modal-close" (click)="closeTimezoneModal()" [routerLink]="">X</a>
        <div class="time-icon">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M28 18C22.475 18 18 22.475 18 28C18 33.525 22.475 38 28 38C33.525 38 38 33.525 38 28C38 22.475 33.525 18 28 18ZM50.35 25.5C49.2 15.075 40.925 6.8 30.5 5.65V3C30.5 1.61929 29.3807 0.5 28 0.5C26.6193 0.5 25.5 1.61929 25.5 3V5.65C15.075 6.8 6.8 15.075 5.65 25.5H3C1.61929 25.5 0.5 26.6193 0.5 28C0.5 29.3807 1.61929 30.5 3 30.5H5.65C6.8 40.925 15.075 49.2 25.5 50.35V53C25.5 54.3807 26.6193 55.5 28 55.5C29.3807 55.5 30.5 54.3807 30.5 53V50.35C40.925 49.2 49.2 40.925 50.35 30.5H53C54.3807 30.5 55.5 29.3807 55.5 28C55.5 26.6193 54.3807 25.5 53 25.5H50.35ZM28 45.5C18.3375 45.5 10.5 37.6625 10.5 28C10.5 18.3375 18.3375 10.5 28 10.5C37.6625 10.5 45.5 18.3375 45.5 28C45.5 37.6625 37.6625 45.5 28 45.5Z"
                    fill="#FF676B" />
            </svg>
        </div>
        <h2>Is it your time zone?</h2>
        <p><strong>{{timezone ? timezone : 'Set timezone'}}</strong>&nbsp;{{timezoneTime ? timezoneTime : ''}}</p>
        <div class="buttons">
            <button (click)="timezoneChangeAction($event)" class="different-timezone">Different time zone</button>
            <button (click)="closeTimezoneModal()" class="same-timezone">Yes</button>
        </div>
    </div>
</div>