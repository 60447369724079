import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { Subject, User } from '../../models';
import { Auth, Backend } from '../../lib/core/auth';
import { Subscription } from 'rxjs/Subscription';
import { SocketService } from '../../lib/core/socket';


interface MenuItem {
    class: string;
    link: string;
    title: string;
    options?: any;
}

@Component({
    selector: 'learnt-popup-menu',
    templateUrl: './popup-menu.component.html',
    styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent implements OnInit {

    public menuItems: MenuItem[];

    private userItems: MenuItem[] = [
        { class: 'dashboard', link: '/main/dashboard', title: 'Dashboard', options: { exact: true } },
        { class: 'refer-tutors', link: '/main/referrals', title: 'Invites' },
        { class: 'account', link: '/main/account', title: 'Your Account', options: { exact: false } },
    ];



    

    private visitorItems : MenuItem[] = [

    ];

    me: User;

    private subs = new Subscription();
    

    @Output() close = new EventEmitter();
    
    constructor(
        private auth: Auth,
        private SocketService: SocketService,
        private router: Router,
    ) {
        this.subs.add(this.auth.me.subscribe((me: User | null) => {
            this.me = me;
            this.menuItems = this.userItems;
            if (this.me) {
                    this.menuItems = this.userItems;
            }
            else
            {
                this.menuItems = this.visitorItems;
            }

        }));
    }

    ngOnInit(): void {
    }

    public admin(event: any): void {
        event.stopPropagation();
        this.router.navigateByUrl('/admin');
    }

    logout(event: MouseEvent) {
        // event.stopPropagation();
        this.auth.logout();
        this.SocketService.disconnect();
    }

}
