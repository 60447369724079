<section>
    <div class="package-header">
        <p>{{ subtitle }}</p>
        <h3>{{ title }}</h3>
    </div>


    <div class="package-body">
        
        <h4>Choose one of our most popular options</h4>
        <div class="plan-container">
            <div class="plan" 
                *ngFor="let item of plans; let i = index" 
                (click)="onSelectPlan(i)"
                [ngClass]="{active: selectedPlan === i}">
                <div class="icon">{{ item.iconUrl }}</div>
                <div class="price">${{ item.price }}</div>
                <div class="description">{{ item.description }}</div>
            </div>
        </div>




        <h4>Or customize your own</h4>

        <div class="plan-custom" [ngClass]="{ active: customPlan }">
            <h5>Choose Quantity</h5>
            <learnt-price-range 
                (change)="onCustomPlanChange($event)"
                [default]="customPriceSelector" 
                [min]="0" 
                [max]="100" 
                [step]="1"
                [value]="customPriceSelector"
                [maxValueInput]="customPlanValue">
            </learnt-price-range>
        
        </div>
        <!--<div class="advantages" *ngIf="customPlanValue >= 12">
            <div class="item">
                <span><img class="tick" src="/assets/tick.svg"></span>
                <span>All 3 AAMC practice tests</span>
            </div>
            <div class="item">
                <span><img class="tick" src="/assets/tick.svg"></span>
                <span>2 Hours consult with MD advisor</span>
            </div>
            <div class="item">
                <span><img class="tick" src="/assets/tick.svg"></span>
                <span>Personal statement review (unlimited revisions)</span>
            </div>        
        </div>-->
    </div>
    <div class="package-footer" >




        <div class="result">
            <div class="total">
                Total Price: 
                <span *ngIf="total">${{ total }}</span>
                <i *ngIf="!total"> Select a plan </i>
            </div>
            <div class="button">
                <ng-container *ngIf="isLoggedIn()">
                    <button class="button" routerLink="/main/account/payments" *ngIf="!hasPayment">
                        Add Payment Method
                    </button>
                    <button mat-button class="button" (click)="goPayment()" *ngIf="hasPayment" [disabled]="!total">
                        Enroll Now
                    </button>
                </ng-container>
                <button class="button" *ngIf="!isLoggedIn()"  (click)="goLogin()"> Log in/Sign up </button>
            </div>
        </div>
        <p class="terms">
            <a href="https://learnt.io/privacy-policy">Privacy Policy</a> - 
            <a href="https://learnt.io/terms-of-use">Terms of Use</a>             
        </p>
    </div>
    
</section>
