<div id="modal-content-wrapper">
    <div id="modal-header">
        <img src="/assets/verification.svg" alt="verification">
        <h1 id="modal-title"> What is This? </h1>
        <span class="close" (click)="closeModal()"><mat-icon svgIcon="close" color="learnt-red"></mat-icon></span>
    </div>
    <section id="modal-body">
        <p class="text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem dicta, ab tempore fuga error impedit, nobis itaque ducimus cum. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem dicta, ab tempore fuga error impedit, nobis itaque ducimus cum.Lorem ipsum dolor sit.
        </p>
        <p class="text">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem dicta, ab tempore fuga error impedit, nobis itaque ducimus cum. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem dicta, ab tempore fuga error impedit, nobis itaque ducimus cum.Lorem ipsum dolor sit amet, 
        </p>
    </section>
    <footer id="modal-footer">
        <button mat-raised-button id="modal-cancel-button" (click)="closeModal()">
            Close
        </button>
    </footer>
</div>