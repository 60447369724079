<div class="icon" (click)="goto()">
    <mat-icon svgIcon="info" color="learnt-white"></mat-icon>
</div>
<div class="info" (click)="goto()">
    <div class="title">{{n?.title}}</div>
    <div class="message">{{n?.message}}</div>
</div>
<button matTooltip="Dismiss" class="close">
    <mat-icon svgIcon="close" color="learnt-white" (click)="close($event)"></mat-icon>
</button>
