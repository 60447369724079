<div class="arrow" *ngIf="arrow">
    <div [class]="arrowDirection"></div>
</div>

<div id="tutor" class="tutor" [class.shadow]="arrow" #card>
    <div class="avatar" [style.background-image]="'url(' + tutor.avatar + ')'">
        <div *ngIf="tutor.is_test_account" class="test-indicator">TEST ACCOUNT</div>
        <div *ngIf="tutor.online" class="online-status">
            <span> Online </span>
        </div>
    </div>

     <div class="content">
        <div class="name-wrapper">
            <span class="name">{{tutor.shortName}} </span>
            <span>{{tutor.getRate(me)|currency:'USD':'symbol'}}/h</span>
        </div>
        
        <div class="rating">
            <div class="left-side-rating">
                <ng-container *ngIf="tutor.tutoring.rating > 0">
                <div class="value">{{tutor.tutoring.rating.toFixed(1)}}</div>
                <learnt-rating [rating]="tutor.tutoring.rating"></learnt-rating>
                </ng-container>
            </div>
            <!--<div class="rigth-side-rating">
                <span>{{tutor.tutoring.rate|currency:'USD':'symbol'}}/hour</span>
            </div>-->
        </div>
        <div class="title-wrapper">
            <span class="title">
                {{tutor.tutoring.title|truncate:50}}
            </span>
        </div>

        <div class="location">
            <img src="/assets/search/tutor-card/map-pin.svg" alt="Map Icon">
            <span *ngIf="tutor.location">{{tutor.location.city + ", " + tutor.location.state | truncate:22}}</span>
        </div>
    </div>
    <button class="instant-session" *ngIf="(tutor.tutoring.instant_session && tutor.online === 1)">
        <img src="/assets/search/tutor-card/instant-session.svg" alt="Instant Session Icon">
        <span>Instant Session</span>
    </button>
    <button class="message-tutor" (click)="messageTutor()" *ngIf="!(tutor.tutoring.instant_session && tutor.online)">
        <img src="/assets/search/tutor-card/message-tutor.svg" alt="Message Tutor Icon">
        <span>Message Tutor</span>
    </button>
    <div class="hover-panel" [ngStyle]="{'left': position }">
        <div class="left-panel-wrapper">
            <a routerLink="/main/tutor/{{tutor._id}}" [queryParams]="{search: true}">
            <div class="avatar" [style.background-image]="'url(' + tutor.avatar + ')'">
                <div *ngIf="tutor.is_test_account" class="test-indicator">TEST ACCOUNT</div>
                <div *ngIf="tutor.online" class="online-status">
                    <span> Online </span>
                </div>
            </div>
        </a>
    
            <div class="content">
                <div class="name-wrapper">
                    <a routerLink="/main/tutor/{{tutor._id}}" [queryParams]="{search: true}">
                    <span class="name">{{tutor.shortName}}</span>
                    </a>
                    <span>{{tutor.getRate(me)|currency:'USD':'symbol'}}/h</span>
                </div>
                
                <div class="rating">
                    <div class="left-side-rating">
                        <ng-container *ngIf="tutor.tutoring.rating > 0">
                        <div class="value">{{tutor.tutoring.rating.toFixed(1)}}</div>
                        <learnt-rating [rating]="tutor.tutoring.rating"></learnt-rating>
                        </ng-container>
                    </div>
                    <!--<div class="rigth-side-rating">
                        <span>{{tutor.tutoring.rate|currency:'USD':'symbol'}}/hour</span>
                    </div>-->
                </div>
                <div class="title-wrapper">
                    <span class="title">
                        {{tutor.tutoring.title|truncate:50}}
                    </span>
                </div>
                <div class="location">
                    <img src="/assets/search/tutor-card/map-pin.svg" alt="Map Icon">
                    <span *ngIf="tutor.location">{{tutor.location.city + ", " + tutor.location.state|truncate:40}}</span>
                </div>
            </div>
            <div class="left-panel-btns">
                <button class="instant-session" (click)="instantSession()"
                *ngIf="(tutor.tutoring.instant_session && tutor.online === 1)">
                    <img src="/assets/search/tutor-card/instant-session.svg" alt="Instant Session Icon">
                    <span>Instant Session</span>
                </button>
                <button class="message-tutor" (click)="messageTutor()">
                    <img src="/assets/search/tutor-card/message-tutor.svg" alt="Message Tutor Icon">
                    <span>Message Tutor</span>
                </button>
            </div>
        </div>
        <div class="right-panel-wrapper">
            <div class="about">
                <div class="about-label">About</div>
                <span>{{tutor.tutoring.title}}</span>
            </div>
    
            <div class="education" *ngIf="loading">
                <learnt-spinner></learnt-spinner>
            </div>
    
            <div class="education" *ngIf="!loading">
                <div class="wrapper-no-scrollbar">
                    <div class="wrapper">
                        <p class="title">Availability</p>
                        <div class="availability" *ngIf="tutor.canMeetOnline" icon="pointer">ONLINE</div>
                        <div class="availability" *ngIf="tutor.canMeetInPerson" icon="pointer">IN PERSON</div>
                    </div>
                    <div class="wrapper">
                        <p class="title">Subjects</p>
    
                        <p class="subject" *ngIf="noSubjects">
                            No subjects added yet.
                        </p>
    
                        <div class="subjects" *ngIf="!noSubjects">
                            
                                <div class="subject-item" *ngFor="let subject of tutor.tutoring.subjects.slice(0, 5)">
                                    {{subject.subject.name|truncate:35}}</div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
            <button class="book-now" mat-raised-button (click)="bookNow()">
                <span>Book Now</span>
            </button>

            <button class="favorite" [disabled]="fetchingFavorite || isMe()" matTooltip="{{ getFavoriteTooltip() }}" (click)="addRemoveFavorite()">
                <mat-icon class="icon" color="primary"  >{{ favoriteIcon() }}</mat-icon>
            </button>
        </div>
    </div>
    <div class="bg-obscure"></div>
</div>
