<div class="front stretch">
    <div class="top">
        <div class="avatar">
            <img [attr.src]="tutor.avatar"/>
        </div>
    </div>

    <div class="name">
        {{tutor.shortName}}
        <span class="online" *ngIf="tutor.online"></span>
    </div>

    <div class="price">
        ${{tutor.getRate(me)}} / hour
    </div>

    <div class="infos gap-10">
        <div class="location" *ngIf="tutor.location">
            <svg width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Profile-/-Desktop-Closed" transform="translate(-49.000000, -232.000000)" fill="#D3D3D3">
                        <g id="Location-+-Rating" transform="translate(49.000000, 227.000000)">
                            <path d="M4,10.4228188 C4.39373831,10.4228188 4.72930448,10.2841183 5.00671055,10.0067106 C5.28411834,9.72930448 5.42281879,9.39373831 5.42281879,9 C5.42281879,8.60626169 5.28411834,8.27069552 5.00671055,7.99328945 C4.72930448,7.71588166 4.39373831,7.57718121 4,7.57718121 C3.60626169,7.57718121 3.27069552,7.71588166 2.99328945,7.99328945 C2.71588166,8.27069552 2.57718121,8.60626169 2.57718121,9 C2.57718121,9.39373831 2.71588166,9.72930448 2.99328945,10.0067106 C3.27069552,10.2841183 3.60626169,10.4228188 4,10.4228188 Z M4,5 C5.10962599,5 6.05368698,5.38925745 6.83221391,6.16778609 C7.61074255,6.94631302 8,7.89037401 8,9 C8,9.55481213 7.86129954,10.1901526 7.58389176,10.9060403 C7.30648569,11.6219279 6.97091952,12.293062 6.57718121,12.9194631 C6.1834429,13.5458642 5.79418545,14.131989 5.40939683,14.6778515 C5.0246065,15.2237163 4.6979883,15.6577166 4.4295302,15.9798658 L4,16.4362416 C3.89261745,16.3109613 3.74944129,16.145415 3.5704698,15.9395973 C3.39149831,15.7337797 3.06935409,15.3221507 2.60402685,14.7046988 C2.1386996,14.0872457 1.7315462,13.4876989 1.38255034,12.9060394 C1.03355533,12.3243816 0.715885101,11.6666695 0.429530201,10.9328859 C0.143175302,10.1991023 6.03961325e-14,9.55481213 6.03961325e-14,9 C6.03961325e-14,7.89037401 0.389257793,6.94631302 1.16778523,6.16778609 C1.94631302,5.38925745 2.89037401,5 4,5 Z" id="icon-location"></path>
                        </g>
                    </g>
                </g>
            </svg>
            <span>{{tutor.location.nice}}</span>
        </div>
        <div class="rate" *ngIf="tutor.tutoring.rating">
            <svg width="11px" height="10px" viewBox="0 0 11 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Profile-/-Desktop-Closed" transform="translate(-168.000000, -233.000000)" fill="#D3D3D3">
                        <g id="Location-+-Rating" transform="translate(49.000000, 227.000000)">
                            <path d="M129.542664,9.67591727 C129.542664,9.76358253 129.487755,9.85921541 129.377936,9.96281859 L127.078063,12.0787128 L127.622937,15.0672644 C127.62716,15.0951576 127.629272,15.1350046 127.629272,15.1868064 C127.629272,15.2704863 127.607097,15.3412147 127.562747,15.3989936 C127.518397,15.4567725 127.453985,15.4856616 127.369507,15.4856616 C127.289255,15.4856616 127.204778,15.4617534 127.116078,15.4139364 L124.271333,14.0033399 L121.426586,15.4139364 C121.333662,15.4617534 121.249187,15.4856616 121.173157,15.4856616 C121.084457,15.4856616 121.017932,15.4567725 120.973582,15.3989936 C120.929232,15.3412147 120.907057,15.2704863 120.907057,15.1868064 C120.907057,15.1628979 120.91128,15.123051 120.919729,15.0672644 L121.464601,12.0787128 L119.158393,9.96281859 C119.052797,9.85522959 119,9.75959805 119,9.67591727 C119,9.52848214 119.118266,9.43683374 119.354801,9.40097074 L122.53534,8.96464204 L123.960882,6.24506026 C124.041135,6.08168586 124.144618,6 124.271333,6 C124.398048,6 124.501531,6.08168586 124.581784,6.24506026 L126.007324,8.96464204 L129.187863,9.40097074 C129.424399,9.43683374 129.542664,9.52848214 129.542664,9.67591727 Z" id="Page-1"></path>
                        </g>
                    </g>
                </g>
            </svg>
            <span>{{tutor.tutoring.rating}}</span>
        </div>
    </div>
</div>
<div class="details stretch">
    <div *ngIf="profileLoading" class="spinner-container stretch">
        <learnt-spinner></learnt-spinner>
    </div>
    <div class="details-container" [class.hidden]="profileLoading">
        <div #details></div>
    </div>
</div>
