import {Logger} from '../lib/core/common/logger';
import {HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, Injectable} from '@angular/core';
import { AlertService } from './alerts';
import { exit } from 'process';
import { environment } from '@environment/environment';

let errors = 0;

@Injectable()
export class NerdlyErrorHandler implements ErrorHandler {

    constructor(private logger: Logger, private alerts: AlertService) {}

    handleError(error: any) {

        if (!error) {
            return;
        }

        if (error instanceof HttpErrorResponse) {

            if (error.status === 0) {
                return;
            }

            //error = new Error(`Status ${error.status} while accesing: ${error.url}`);

            console.log(error);

            return;
            
        }


        if(error instanceof String){

           /* error = JSON.parse(String(error));
            

            const a = this.alerts.alert(
                'An error occurred',
                error.message,
                {
                    lifetime: 0,
                    backdropClose : true,
                    buttons: [
                        { label: 'OK', result: false}
                    ]
                }
            );

            a.result.subscribe(() => a.dispose())
            */

            console.log(error);
            

        }

        if (error instanceof Error) {
            
            console.log(error);
            
            if(environment.production == false){

                const a = this.alerts.alert(
                    'An error occurred',"",
                    {
                        lifetime: 0,
                        backdropClose : true,
                        buttons: [
                            { label: 'OK', result: false}
                        ]
                    }
                );

                a.result.subscribe(() => a.dispose())

            }
        }
    }
}
