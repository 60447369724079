<div class="header"  *ngIf="message">
    <h1>{{title}}</h1>
</div>
<div class="content">

    <div class="icon" *ngIf=" (icon) || (!message && !icon) ">
        <img src="{{ icon }}" alt="">
    </div>

    <div class="inner-title" *ngIf="!message">
        <h1>{{title}}</h1>
    </div>
    <div class="text" *ngIf="message" [innerHTML]="message"></div>

</div>
<div class="buttons" *ngIf="buttons">
    
        <button mat-raised-button *ngFor="let button of buttons" (click)="result.next(button.result)">{{button.label}}</button>
    
</div>

<!--<svg width="45px" height="45px" viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="z" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Desktop---027-Updated" transform="translate(-656.000000, -417.000000)">
            <g id="Updated">
                <g id="icon" transform="translate(656.000000, 417.000000)">
                    <circle id="Oval-4" fill="#FFFFFF" cx="22.5" cy="22.5" r="22.5"></circle>
                    <path d="M20.6408215,29.7428652 C20.6837819,29.7612759 20.708328,29.7674128 20.7328764,29.7796867 C20.7574248,29.7919606 20.7696979,29.7980974 20.7696979,29.7980974 C20.8433409,29.8226452 20.8924354,29.8471926 20.9169838,29.8717404 L21.0642698,29.8717404 C21.1379128,29.8962882 21.1992827,29.8962882 21.2483772,29.8717404 L21.3220202,29.8717404 C21.4202116,29.8471926 21.5061277,29.8103715 21.5797706,29.7612759 C21.5797706,29.7612759 21.5920437,29.7490022 21.6165921,29.7244544 C21.6902351,29.6753589 21.7331931,29.6446747 21.7454662,29.6324007 C21.7577416,29.6201267 21.7700146,29.6017162 21.78229,29.5771685 C21.794563,29.5526207 21.8006996,29.540347 21.8006996,29.540347 L28.8336039,18.2729714 C28.9563438,18.0765886 28.9931629,17.867935 28.9440684,17.6470061 C28.8949739,17.4260772 28.7783729,17.2542451 28.5942631,17.1315052 C28.4101556,17.0087654 28.2076386,16.9719462 27.9867097,17.0210408 C27.7657808,17.0701353 27.5939487,17.1805998 27.4712089,17.3524342 L20.9538053,27.7729155 L17.3821209,24.2748741 C17.2102865,24.1275881 17.0139072,24.0539452 16.7929771,24.0539452 C16.572047,24.0539452 16.3818047,24.1398612 16.222244,24.3116956 C16.0626834,24.48353 15.9890412,24.686047 16.0013151,24.919249 C16.013589,25.1524533 16.1056418,25.3426973 16.2774762,25.4899832 L20.5855904,29.687633 C20.6162754,29.7183176 20.6285485,29.7305913 20.6408215,29.7428652 Z"
                        id="Page-1" fill="#F37073"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
<div layout="column" class="messages">
    <div class="closeBtn" *ngIf="closeButton">
        <button mat-mini-fab (click)="result.next(0)">X</button>
    </div>
    <h1>{{title}}</h1>
    <p *ngIf="message" [innerHTML]="message"></p>
    <div *ngIf="buttons" class="buttons gap-10" layout="row">
        <button mat-raised-button *ngFor="let button of buttons" (click)="result.next(button.result)">{{button.label}}</button>
    </div>
</div>-->