import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '../../lib/core/auth';
import { User } from '../../models/user';

@Component({
    selector: 'learnt-tutor-box',
    templateUrl: './tutor-box.component.html',
    styleUrls: ['./tutor-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TutorBoxComponent {

    @Input()
    tutor: User;

    @Input()
    link = true;

    @Input()
    width = 270;

    @Input()
    height = 400;

    @Input()
    test_prep: false;

    constructor(private auth: Auth,
        private router: Router) {}

}
