import { ChangeDetectionStrategy,ChangeDetectorRef,Component,ElementRef,Inject,OnDestroy,OnInit,ViewChild} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { APP_ROUTES, IRoutes, ROUTE_TUTORS } from 'routes';
import { Subscription } from 'rxjs';
import { Backend } from '../../lib/core/auth';
import { Media } from '../../lib/core/media';
import { Subject } from '../../models';

@Component({
    selector: 'learnt-not-found-page',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class  NotFoundComponent  implements OnInit, OnDestroy {
    @ViewChild('main', {static: true}) mainEl: ElementRef;
    public signUpRole: 'student' | 'tutor' = 'student';
    public signUpForm: FormGroup;
    public mobile: boolean;
    private subs = new Subscription();
    subjectSearchResults: Subject[] = [];
    subjectField: FormControl;

    constructor(
        private router: Router,
        private media: Media,
        private cdRef: ChangeDetectorRef,
        private backend: Backend,
        @Inject(APP_ROUTES) private routes: IRoutes) {
        this.mobile = !media.query('gt-sm');
        this.subs.add(media.watch('gt-sm').subscribe((media) => {
            this.mobile = !media.active;
            this.cdRef.detectChanges();
        }));
        document.body.parentElement.classList.add('learnt-theme--2');
        this.subjectField = new FormControl('');
    }

    ngOnInit(): void {}

 

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    searchSubject(): void {
        if (!this.subjectField.value) {
            return;
        }
        this.subs.add(this.backend.getSubjects(this.subjectField.value, 100).subscribe((subjects: Subject[]) => {
           this.subjectSearchResults = subjects;
           this.cdRef.detectChanges();
        }));
    }

    subjectName(subject: Subject | null): string | null {
        return subject ? subject.name : null;
    }

    onSelectSubject(evt: MatAutocompleteSelectedEvent): void {
        this.router.navigateByUrl(`${ROUTE_TUTORS}?query=${(evt.option.value as Subject).name}`)
    }

 
    navigate(url: string): void {
        this.router.navigateByUrl(url);
    }
}
