<section *ngIf="!paymentSuccesfull">
    <div class="package-header">        
        <h3>Cart</h3>
        <p>Hey! Thanks for choosing Learnt. <br>You will receive an automatic email confirm your payment.</p>
    </div>

    <div class="package-body">         
        <p>Your Personal Academic Strategist will be in touch with you within 24 hours to schedule your first session.</p>
        <p>Each test prep package includes a Free Diagnostic Hour.</p>
        <p>Questions? Click the chat bubble at the bottom, right-hand corner of your screen and we can help!</p>
        
        <div class="plan-chosen">
            <table>
                <tr>
                    <td></td>
                    <td>Product</td>
                    <td>Plan</td>
                    <td>Price</td>
                </tr>
                <tr>
                    <td>
                        <img src="/assets/c-check.svg" alt="" width="20"> 
                    </td>

                    <td>
                        <span>{{ title }}</span>
                    </td>
                    <td>
                        <span>{{ planText }} - {{ hours }}hs.</span>
                    </td>
                    <td>
                        <span>${{ total }}</span>
                    </td>
                </tr>

            </table>
        </div>

        
    </div>
    <div class="package-footer">
        <div class="package-result">
            <h4>Cart totals</h4>
            <table>
                <tr>
                    <td>Subtotal</td>
                    <td>$ {{ subtotal }}</td>            
                </tr>
                <tr>
                    <td>Discount</td>
                    <td>$ {{ discount }}</td>            
                </tr>
                <tr>
                    <td>Total</td>
                    <td>$ {{ total }}</td>            
                </tr>
            </table>
        </div>
        <div class="buttons">

           
            <p class="subtitle" *ngIf="ccNum">Your card on file ending in {{ccNum}} will be charged.</p>
            <button (click)="cancel()" class="button secondary">Cancel</button>
            <button (click)="submit()"class="button primary">Confirm</button>
        </div>
    </div>

</section>
