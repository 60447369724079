import { HydrateProperty, Hydratable } from "@app/lib/core/hydratable";

export class City extends Hydratable {
    @HydrateProperty()
    _id: string;

    @HydrateProperty()
    name: string;

    @HydrateProperty()
    state: string;

    @HydrateProperty()
    slug: string;

    @HydrateProperty()
    landing: any;

    @HydrateProperty()
    published: boolean;
   

    constructor(raw?: any) {
        super();
        if (raw) {
            this.hydrate(raw);
        }
    }

}