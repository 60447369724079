<div class="classes" flex layout="column">
    <div *ngIf="perMonth" class="header" layout="row" layout-align="center center">
        <div layout="row" class="navigate" layout-align="center center">
            <a (click)="navigate(-1)">
                <mat-icon svgIcon="arrow-left"></mat-icon>
            </a>
            <div>{{date.format('MMMM YYYY')}}</div>
            <a (click)="navigate(1)">
                <mat-icon svgIcon="arrow-right"></mat-icon>
            </a>
        </div>
        <div flex></div>
        <a routerLink="/main/account/lessons">See Lesson History</a>
    </div>

    <ul class="list" flex *ngIf="!working && lessons !== null && lessons.length > 0" [class.paginated]="paginated">
        <ng-container *ngFor="let lesson of lessons; let ix = index;">
            <li layout="row" 
                layout-align="center center" 
                [class.clickable]="interactive || lesson.state === 2"
                [class.needaction]="me.isTutor() && lesson.state == 0" 
                routerLink="/main/account/calendar/details/{{ lesson._id }}"
                >
                <div class="date">
                    {{lesson.starts_at.format(' DD')}}
                    {{lesson.starts_at.format('MMM')}}
                </div>

                <div class="info" flex>
                    <div class="subject">{{lesson.subject.name}}</div>
                </div>

                <div>
                    <div class="whenwhere">
                        <span>{{lesson.starts_at.format('h:mma')}} to {{lesson.ends_at.format('h:mma')}}</span><br>
                        <span *ngIf="lesson.meet === 2"> Online</span>
                        <span *ngIf="lesson.meet === 4">{{lesson.location}}</span>
                    </div>
                </div>

                <ng-container [ngSwitch]="lesson.state">
                    <div *ngSwitchCase="0" class="status pending" >
                        <mat-icon svgIcon="time" color="learnt-white"></mat-icon>

                        <span *ngIf="me.isStudent()">Booked</span>
                        <span *ngIf="me.isTutor()">Need action</span>
                        

                    </div>
                    <div *ngSwitchCase="1" class="status confirmed">
                        <mat-icon svgIcon="tick" color="learnt-white"></mat-icon>
                        <span>Upcoming</span>
                    </div>
                    <div *ngSwitchCase="2" class="status progress">
                        <mat-icon svgIcon="circle-tick" color="learnt-white"></mat-icon>
                        <span>In Progress</span>
                    </div>
                    <div *ngSwitchCase="3" class="status completed">
                        <mat-icon svgIcon="tick" color="learnt-white"></mat-icon>
                        <span>Completed</span>
                    </div>
                    <div *ngSwitchCase="4" class="status cancelled">
                        <mat-icon svgIcon="close" color="learnt-white"></mat-icon>
                        <span>Declined</span>
                    </div>
                    <div *ngSwitchCase="5" class="status incompleted">
                        <mat-icon svgIcon="close" color="learnt-white"></mat-icon>
                        <span>Incompleted</span>
                    </div>                    
                </ng-container>
            </li>

            <!-- <li #lessonMenuElem layout="row" layout-align="center center" class="lesson-menu" [class.visible]="lessonMenu === ix">
                <button mat-raised-button (click)="editLesson(lesson)" [disabled]="!canBeModified(lesson)">Edit Class</button>
                <button mat-raised-button (click)="cancelLesson(lesson)" [disabled]="!canBeCancelled(lesson)">Cancel Class</button>
            </li> -->
        </ng-container>
    </ul>

    <learnt-pagination *ngIf="paginated" [pages]="pages" (page)="getPage($event)"></learnt-pagination>

    <div class="empty-list" flex *ngIf="!working && (!lessons || lessons.length == 0)" layout="row" layout-align="start center">
        <div layout="column" layout-align="center center">
            <mat-icon svgIcon="calendar-solid"></mat-icon>
            <span>No activity.</span>
        </div>
    </div>

    <div class="working" flex *ngIf="working" layout="row" layout-align="center center">
        <learnt-spinner></learnt-spinner>
    </div>
</div>
