<div class="price-controller">
    <div class="price-container" #bar>
        <div class="progress" #progress></div>
        <div class="handler handler-left" data-kind="min" #handlerLeft></div>
        <div class="handler" data-kind="max" #handlerRight></div>
    </div>
    <div class="min-max" layout="row" layout-align="space-between center">
        <div class="min">{{min}}</div>
        <div class="max">{{max}}</div>
    </div>
</div>
<div class="result">
  <!--<span>{{value.min}}</span>
  <span>&nbsp;-&nbsp;</span>-->
 <div>Total hours:&nbsp;<span>{{value.max}}</span></div> 
</div>
