import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
 
@Injectable({
   providedIn: 'root'
})
 
export class CanonicalService { 
   constructor(@Inject(DOCUMENT) private dom) { 

   }
    
   createCanonicalURL(currentURL? : string) {

    if(currentURL !== undefined){
        
        const fullURL = environment.REFERRAL_URL + currentURL
        const links:any[] = [...this.dom.getElementsByTagName("link")];
        const canonicalTag = links.find( x => x.rel === 'canonical')
        if(canonicalTag) 
            canonicalTag.setAttribute('href',fullURL);
        else{
            const linkTag: HTMLLinkElement = this.dom.createElement('link');
            linkTag.setAttribute('rel', 'canonical');
            this.dom.head.appendChild(linkTag);
            linkTag.setAttribute('href', fullURL);
        }
    }
   }
} 
