<input #input [attr.name]="name" [attr.id]="id" [attr.type]="type" (focus)="focused = true" (blur)="focused = false"
    (change)="onValueChange($event)" [(ngModel)]="checked" />

<ng-container *ngIf="style === 'old'">
    <div [class.disabled]="disabled">
        <svg *ngIf="checked" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.69231L4.52632 7L10 2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>            
    </div>
</ng-container>

<ng-container *ngIf="style == 'new'">
    <div [class.disabled]="disabled" class="checkbox" [class.checked]="checked" *ngIf="style === 'new'">
        <svg *ngIf="checked" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="6" fill="#FF676B" />
            <path d="M8 12.6923L10.5263 15L16 10" stroke="white" stroke-width="3" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </div>
</ng-container>
<label [attr.for]="id" [class.checkbox__label]="style==='new'" [class.selected]="checked">
    <ng-content></ng-content>
</label>