import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Auth } from '@app/lib/core/auth';
import { Plan } from '@app/models/plan';
import { PackagePaymentComponent } from '../package-payment/package-payment.component';
import { PriceIntervalValue } from '../price-interval/price-interval.component';
import { Router } from '@angular/router';
import { ROUTE_LOGIN } from '@routes';
import { toQueryString } from '@app/lib/core/utils';
@Component({
    selector: 'learnt-package',
    templateUrl: './package.component.html',
    styleUrls: ['./package.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class PackageComponent implements OnInit{

    @Input() subject;
    @Input() title; 
    @Input() subtitle;
    @Input() individualPrice = 30;
    @Input() plans:Plan[] = []; 

    customPriceSelector = new PriceIntervalValue(0, 50);
    
    selectedPlan:number | undefined;
    customPlan:number | undefined
    total: number | undefined
    customPlanValue: number | undefined;
    planId: string | undefined;

    public hasPayment:boolean;

    constructor(
        private auth: Auth, 
        public dialog: MatDialog,
        private router: Router
    ){}
    
    ngOnInit():void{
        const mockPlans:Plan[] = [{
            price:1200,
            hours:12,        
            description: 'Basic Plan'
        }];

        if (this.plans.length === 0) {
            this.plans = mockPlans;
        }

        if(this.auth.isLoggedIn()){
            this.auth.getMe().subscribe(
                me => this.hasPayment = !!me.card  
            )
        }

        let scroll = sessionStorage.getItem("package")
        if (scroll !== null) {
            document.getElementsByClassName("package-body")[0].scrollIntoView();
            sessionStorage.removeItem("package")
        }
    }

    public isLoggedIn():boolean{
        return this.auth.isLoggedIn()
    }

    public onCustomPlanChange(data:PriceIntervalValue):void{
        const planFoundIndex = this.plans.findIndex(plan => plan.hours === data.max)
        if(planFoundIndex > -1){
            this.onSelectPlan(planFoundIndex);
        }
        else{
            this.selectedPlan = undefined;
            this.planId = undefined;
            this.customPlan = data.max;
            this.customPlanValue = data.max;
            this.setTotal();
        }
    }

    public onSelectPlan(index:number):void{                      
        this.selectedPlan = index;        
        this.customPlan = undefined;
        this.planId = this.plans[this.selectedPlan].id;
        this.customPlanValue = this.plans[this.selectedPlan].hours        ;
        this.setTotal();
    }

    private setTotal():void{
        if(this.selectedPlan !== undefined){
            this.total = this.plans[this.selectedPlan].price            
        }
        if(this.customPlan !== undefined){
            this.total = this.individualPrice * this.customPlan
        }
    }

    public goPayment():void{
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height = '80vh'
        dialogConfig.width = '80vw'
        dialogConfig.data = {
            subject: this.subject,
            discount: 0,
            title: this.title,
            selectedPlan: (this.selectedPlan) ? this.plans[this.selectedPlan] : undefined,
            customPlan: this.customPlan,
            total: this.total,
            hours: this.customPlanValue,
            planId: this.planId,
        }
        const dialogRef = this.dialog.open( PackagePaymentComponent , dialogConfig );        
        dialogRef.afterClosed().subscribe((status) => {
            console.log(status);                       
        });            
    }

    public goLogin(): void {
        sessionStorage.setItem("package", "true")
        this.router.navigateByUrl(`${ROUTE_LOGIN}?${toQueryString({ redirect: this.router.url })}`)
    }
}


