<div class="value" layout="row" layout-align="left center">
  <span>{{value.min}}</span>
  <span>&nbsp;-&nbsp;</span>
  <span>{{value.max}}&nbsp;$</span>
</div>
<div class="price-controller">
  <div class="price-container" #bar>
    <div class="progress" #progress></div>
    <div class="handler" data-kind="min" #handlerLeft></div>
    <div class="handler" data-kind="max" #handlerRight></div>
  </div>
  <div class="min-max" layout="row" layout-align="space-between center">
    <div class="min">{{min}}$</div>
    <div class="max">{{max}}$</div>
  </div>
</div>