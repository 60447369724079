import {Hydratable, HydrateProperty} from '../lib/core/hydratable';

export class Subject extends Hydratable {
    @HydrateProperty()
    _id: string;

    @HydrateProperty()
    name: string;

    @HydrateProperty()
    subject: string;

    @HydrateProperty()
    isTestPrep?: boolean;

    @HydrateProperty()
    slug: string;

    @HydrateProperty()
    landing: any;

    @HydrateProperty()
    published: boolean;
   
    @HydrateProperty()
    package: any;

    constructor(raw?: any) {
        super();
        if (raw) {
            this.hydrate(raw);
        }
    }

}

export interface Faq {
    question: string;
    answer: string;
}

export interface Event {
    name: string;
    date: Date;
}
