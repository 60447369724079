import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from "@angular/common/http";
import { map, take, tap } from "rxjs/operators";
import { State } from "../models/state";
import { Observable } from "rxjs/Observable";
import { City } from "../models/city";
import { toQueryString } from "../lib/core/utils";
import _uniq from 'lodash-es/uniqWith';
import { STATES } from "../lib/helpers/states";

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    states: State[] = [];
    cities: City[] = [];
    
    private httpClient: HttpClient;

    constructor(
        private http: HttpClient,
        private handler: HttpBackend,
    ) {

        this.httpClient = new HttpClient(handler);
        this.states = STATES;
    }

    async getCities(q: string): Promise<void> {
        if (!q) {
            this.cities = [];
            return;
        }
        await this.http.get(`@api/countries/5936ac0bdc27f966e6216971/cities?${toQueryString({q})}`).subscribe((data: {[p:string]: any}[]) => {
            this.cities = _uniq(data.map(raw => new City(raw.name)), (a: City, b: City) => (a.name === b.name));
        });
    }

    async getFullStateName(state:string): Promise<string>{
        return await STATES.find( x => x.code === state ).name
    }


    getCords(city:string, state: string):Observable<any>{
        const url = `https://nominatim.openstreetmap.org/search?format=json&q=${ city },${ state }&addressdetails=0`
        return this.http.get(url).pipe(
            map( (response:any[]) =>response.find( e => true) ),            
        )
    }


    getPlaces(lat,lng){
        //TODO: Fix this ASAP       
        /*
        const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-${ lat }%2C${ lng }&radius=1500&type=Tutoring service&key=${ environment.GoogleMapsApiKey }`;
        const url2 = `https://maps.googleapis.com/maps/api/place/details/json?fields=name%2Crating%2Cformatted_phone_number&place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&key=${ environment.GoogleMapsApiKey }`
        const url3 = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522,151.1957362&radius=11500&types=tutorig&name=learnt&key=${ environment.GoogleMapsApiKey }`
        const url4 = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&input=Museum%20of%20Contemporary%20Art%20Australia&inputtype=textquery&key=${ environment.GoogleMapsApiKey }`

        return this.httpClient.get(url4).pipe(
            tap( e => console.log(e)),
            //map( (response:any[]) =>response.find( e => true) ),            
        )*/
    }

    
}
