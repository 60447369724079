import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Backend } from '@app/lib/core/auth';
import { NoteType, UserNote } from "../../models";

@Component({
    selector: 'learnt-user-notes',
    templateUrl: './user-notes.component.html',
    styleUrls: ['./user-notes.component.scss']
})
export class UserNotesComponent implements OnInit {
    @Input() notes: UserNote[];    
    @Output() deleteNoteEvent = new EventEmitter<string>();
    noteTypes = NoteType;
    constructor(
        private backend: Backend         
    ) {}

    ngOnInit(): void{

     

    }

    delete(note:string):void{
        this.deleteNoteEvent.emit(note)                    
    }
    

}
