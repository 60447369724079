<div class="ssn__container">
    <input type="text" maxlength="1">
    <input type="text" maxlength="1">
    <input type="text" maxlength="1">
    <div class="divider">&ndash;</div>
    <input type="text" maxlength="1">
    <input type="text" maxlength="1">
    <div class="divider">&ndash;</div>
    <input type="text" maxlength="1">
    <input type="text" maxlength="1">
    <input type="text" maxlength="1">
    <input type="text" maxlength="1">
</div>
