<div class="preloader" *ngIf="loading">
  <learnt-spinner></learnt-spinner>
</div>
<div *ngIf="!loading">
  <div class="modal-content">
    <div class="head" layout="column" layout-align="center center">
      <div class="close-button">
        <a class="" (click)="visibility = 'hidden'">        
          <mat-icon svgIcon="close" color="learnt-red"></mat-icon>
        </a>
      </div>
      <h3 flex>Please set your time zone</h3>
      
    </div>
    <div class="tzlist">
      <div class="tz" *ngFor="let tz of timezones" layout="row" (click)="select(tz)" ng-class="{'bold':selectedVal!='Choose'}">
        <div class="name" flex>{{tz.zone}}</div>
        <div class="offset">{{tz.time}}</div>
      </div>
    </div>
  </div>
</div>