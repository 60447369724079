<learnt-menu></learnt-menu>
<main>
    <div class="page page--0" id="page--0">
        <div class="page__container">
            
            <div class="content">
                <span class="graphics emoji-wrong mb-3"></span>
                <header class="title mb-3">
                    <h1>404 - Page Not Found <br><span class="title__brand">Learnt</span></h1>                    
                </header>
                <p class="paragraph">
                    Learnt's team tutors a plethora of subjects and difficulty levels.<br *ngIf="!mobile">
                    Search a subject to browse tutors available. Instantly.
                </p>
                <mat-form-field class="main-search" appearance="outline">
                    <mat-label><label>Search a subject&hellip;</label></mat-label>
                    <input matInput type="text" placeholder="Search subject" [formControl]="subjectField"
                           [matAutocomplete]="subjectAuto">                    

                    <div class="search-icon" >
                        <div class="icon">
                            <img src="/assets/search.svg" alt="Search Icon" />
                        </div>
                    </div>

                    <mat-autocomplete #subjectAuto="matAutocomplete" [displayWith]="subjectName"
                                      (optionSelected)="onSelectSubject($event)">
                        <mat-option *ngFor="let option of subjectSearchResults" [value]="option">
                            {{ option.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <div class="partners">
                    <img src="assets/icons/nasdaq.svg" alt="Nasdaq logo">
                    <img src="assets/icons/business-insider.svg" alt="Business Insider logo">
                </div>
            </div>
        </div>
    </div>

</main>

